import React from 'react'
import LottieError from '../elements/LottieError'

const ErrorPage = () => {
  return (
    <>
      <LottieError />
    </>
  )
}

export default ErrorPage
